//4号类型组件
<template>
  <div class="study_exercises">
    <!-- 内容区域 -->
    <div class="content" v-if="!showLoading">
      <!-- 题干内容部分 -->
      <div class="stem_content">{{ title }}</div>

      <!-- 添加图片 -->
      <div class="add_img">
        <!-- 添加图片按钮 -->
        <el-upload
          @click.stop.native
          :auto-upload="true"
          action="#"
          :show-file-list="false"
          class="upload_img"
          :before-upload="beforeAvatarUploadImg"
        >
          <div class="add_text">添加图片</div>
          <span
            class="iconfont add_icon cloud-zhaoshangxiaochengxu-jiahao"
          ></span>
        </el-upload>

        <!-- 展示添加图片的列表区域 -->
        <div
          :class="[
            'item_course',
            (index + 2) % 4 != 0 ? 'hasMargin' : 'noMargin',
          ]"
          v-for="(item, index) in imgArr"
          :key="index"
        >
          <div>
            <img
              :preview="item.row_id"
              class="item_img"
              :src="item.work_file"
            />
          </div>
          <div class="item_bottom">
            <div class="item_bottom_left" :title="item.work_content">
              {{ item.work_content }}
            </div>

            <div class="item_bottom_right">
              <span
                class="iconfont item_icon cloud-bianji1"
                @click.stop="doEditAction(item, 1)"
              ></span>
              <span
                class="iconfont item_icon cloud-shanchu"
                @click.stop="doDelAction(item, 1)"
              ></span>
            </div>
          </div>
        </div>
      </div>

      <!-- 添加视频 -->
      <div class="add_video">
        <!-- 添加图片按钮 -->
        <el-upload
          @click.stop.native
          :auto-upload="true"
          action="#"
          :show-file-list="false"
          class="upload_img"
          :before-upload="beforeAvatarUploadVideo"
        >
          <div class="add_text">添加视频</div>
          <span
            class="iconfont add_icon cloud-zhaoshangxiaochengxu-jiahao"
          ></span>
        </el-upload>

        <!-- 展示添加视频的列表区域 -->
        <div
          :class="[
            'item_course',
            (index + 2) % 4 != 0 ? 'hasMargin' : 'noMargin',
          ]"
          v-for="(item, index) in videoArr"
          :key="index"
          @click.stop="showVideoDialog(item)"
        >
          <div>
            <img class="item_img" :src="item.work_cover" />
          </div>
          <div class="item_bottom">
            <div class="item_bottom_left" :title="item.work_content">
              {{ item.work_content }}
            </div>

            <div class="item_bottom_right">
              <span
                class="iconfont item_icon cloud-bianji1"
                @click.stop="doEditAction(item, 2)"
              ></span>
              <span
                class="iconfont item_icon cloud-shanchu"
                @click.stop="doDelAction(item, 2)"
              ></span>
            </div>
          </div>
        </div>
      </div>

      <!-- 添加项目-->
      <div class="add_project">
        <!-- 添加图片按钮 -->
        <div class="upload_img" @click.stop="showCrateProject">
          <div class="add_text">添加项目</div>
          <span
            class="iconfont add_icon cloud-zhaoshangxiaochengxu-jiahao"
          ></span>
        </div>

        <!-- 展示添加项目的列表区域 -->
        <div
          :class="[
            'item_course',
            (index + 2) % 4 != 0 ? 'hasMargin' : 'noMargin',
          ]"
          v-for="(item, index) in projectArr"
          :key="index"
          @click.stop="playItemProject(item)"
        >
          <div>
            <!-- <el-image
              class="item_img"
              :src="item.work_cover"
              fit="cover"
              
            ></el-image> -->
            <el-image class="item_img" :src="item.work_cover" fit="cover" v-if="item.work_type == 3">
                <div slot="error" class="image-slot">
                  <el-image :src='require("../../assets/img/default-work-img.png")' fit="cover"></el-image>
                </div>
            </el-image>
          </div>
          <div class="item_bottom">
            <div class="item_bottom_left" :title="item.work_file">
              {{ item.work_file }}
            </div>

            <div class="item_bottom_right">
              <span
                class="iconfont item_icon cloud-bianji1"
                @click.stop="showCrateProject(item)"
              ></span>
              <span
                class="iconfont item_icon cloud-shanchu"
                @click.stop="doDelAction(item, 3)"
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loading v-if="showLoading"></loading>

    <!-- 删除的弹框 -->
    <DelItemDialog
      ref="del_item"
      v-if="showDelDialog"
      :showDialog="showDelDialog"
      @close="showDelDialog = false"
      :title="delTitle"
      @delItem="delItem"
    ></DelItemDialog>

    <!-- 修改的弹框 -->
    <EditNameDialog
      ref="edit_name"
      v-if="showEditDialog"
      :showDialog="showEditDialog"
      @close="showEditDialog = false"
      :title="editTitle"
      @editItemName="editItemName"
    ></EditNameDialog>

    <!-- 播放视频的弹框 -->
    <PlayVideoDialog
      v-if="showPlayVideoDialog"
      :showDialog="showPlayVideoDialog"
      @close="showPlayVideoDialog = false"
      :title="viedoName"
      :playObj="playObj"
    >
    </PlayVideoDialog>

    <!-- 创建项目的弹框 -->
    <CreateCourse
      v-if="showCreateCourseDialog"
      @close="showCreateCourseDialog = false"
      :showDialog="showCreateCourseDialog"
      title="添加项目"
      @doCreateCourse="doCreateCourse"
    />

    <!-- 播放项目的弹框 -->
    <PlayProjectDialog
      v-if="showPlayProjectDialog"
      @close="showPlayProjectDialog = false"
      :showDialog="showPlayProjectDialog"
      :projectInfo="projectInfo"
    />

    <!-- 创作作品的弹框 -->
    <ShowCreateProject
      v-if="showCreateProjectDialog"
      :showDialog="showCreateProjectDialog"
      @close="showCreateProjectDialog = false"
      title="创建作品"
      :iframeSrc="iframeSrc"
      :projectInfo="projectInfo"
    ></ShowCreateProject>
  </div>
</template>
<script>
//编辑名称的弹框
import EditNameDialog from "@/views/dialog/EditNameDialog";

//删除条目的弹框
import DelItemDialog from "@/views/dialog/DelItemDialog";

//播放视频的弹框
import PlayVideoDialog from "@/views/dialog/PlayVideoDialog";

//创建项目作品弹框
import CreateCourse from "@/views/dialog/CreateCourse";

//播放项目的弹框
import PlayProjectDialog from "@/views/dialog/PlayProjectDialog";

//创建作品的弹框
import ShowCreateProject from "@/views/dialog/ShowCreateProject";

export default {
  name: "ExercisesStudy",
  data() {
    return {
      //页面加载的Loading
      showLoading: true,

      //上传的Loading框
      loading: null,

      //编辑弹框的标题
      editTitle: "编辑",

      //是否显示编辑弹框的控制值
      showEditDialog: false,

      //删除弹框的条比
      delTitle: "删除",

      //是否显示编辑项目弹框的控制值
      showDelDialog: false,

      //视频播放的名称
      viedoName: "",

      //项目名称
      projectName: "",

      //编辑弹框的标题
      editTitle: "编辑",

      //是否显示播放视频弹框的控制值
      showPlayVideoDialog: false,

      //是否显示创建项目的弹框
      showCreateCourseDialog: false,

      //是否显示播放项目的弹框
      showPlayProjectDialog: false,

      //上传的图片
      imgArr: [],

      //视频
      videoArr: [],

      //项目
      projectArr: [],

      //需要被编辑或者删除的条目
      actionObj: null,

      //操作的类型 1图片 2视频 3项目
      actionType: -1,

      //播放视频传递的对象
      playObj: null,

      //题干
      title: "",

      //创建作品的弹框
      showCreateProjectDialog: false,
      iframeSrc: "",
      iframeHost: this.$api.SCRATCH3_URL,
      projectInfo: "",
    };
  },

  created() {
    // console.log("执行当前页面了");
    //请求页面数据
    this.httpData();
  },

  mounted() {
    window.addEventListener(
      "message",
      (e) => {
        var params = e.data;
        this.showCreateProjectDialog = false;
        this.httpData();
      },
      false
    );
  },

  methods: {
    //获取页面列表数据
    async httpData() {
      let param = new Object();
      param.type = 4;
      param.curriculum_center_id = this.curriculumCenterId;
      param.curriculum_chapter_id = this.curriculumChapterId;
      param.chapter_class_id = this.chapterClassId;
      param.uid = this.getUserInfo().uid;
      param.token = this.getUserInfo().token;
      param.section_id = 4;
      let res = await this.$http.fetchPost(this.$api.POST_ACTION, param);
      if (res.data.state == 200) {
        //改变加载状态
        this.showLoading = false;
        //分别获取图片,视频,项目的列表数据
        if (!this.isEmpty(res.data.data.work_list1)) {
          this.imgArr = res.data.data.work_list1;
        }
        if (!this.isEmpty(res.data.data.work_list2)) {
          this.videoArr = res.data.data.work_list2;
        }
        if (!this.isEmpty(res.data.data.work_list3)) {
          this.projectArr = res.data.data.work_list3;
        }
        //获取题干
        this.title = res.data.data.title;
        this.$previewRefresh();
      } else {
        //直接将服务器返回的错误内容弹出提示
        this.showErrorMsg(this.getMesage(res.data.state));
      }
    },

    //选择图片文件的操作
    beforeAvatarUploadImg(file) {
      if (this.imgArr.length >= 9) {
        this.showErrorMsg("最多创建9个项目");
        return;
      }
      //限定只能是JPEG或者PNG格式并且图片大小不超过2MB

      // console.log("文件的类型是:", file.type);

      //jpeg的限定
      const isJPG = file.type === "image/jpeg";

      //png格式的限定
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPNG) {
        this.showErrorMsg("上传图片只能是 JPG或者PNG 格式!");
        return;
      }
      if (!isLt2M) {
        this.showErrorMsg("上传图片大小不能超过 2MB!");
        return;
      }

      //JPG和PNG并且大小限定2M
      const res = (isJPG || isPNG) && isLt2M;
      if (res) {
        //开启loading
        this.showUploadLoading();

        //上传文件
        this.uploadFile(1, file);
      }
      return res;
    },

    //获取公共请求参数 work_type 图片1,视频2, file上传的文件
    getComParams(work_type, file) {
      let param = new FormData();
      param.append("type", 1);
      param.append("uid", this.getUserInfo().uid);
      param.append("curriculum_center_id", this.curriculumCenterId);
      param.append("curriculum_chapter_id", this.curriculumChapterId);
      param.append("chapter_class_id", this.chapterClassId);
      param.append("work_type", work_type);
      param.append("section_id", 4);
      param.append("file", file);
      param.append("token", this.getUserInfo().token);
      return param;
    },

    /**
     * 定义上传文件的方法
     * @param type 上传的文件类型 1图片 2视频
     * @param file 上传的文件
     * @returns
     */
    uploadFile(type, file) {
      this.$http
        .fetchFormDataPost(this.$api.POST_ACTION, this.getComParams(type, file))
        .then((res) => {
          //关闭弹框
          this.closeLoading();
          if (res.data.state == 200) {
            //重新请求页面数据
            this.httpData();
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.closeLoading();
          this.showErrorMsg("服务器请求异常,上传失败");
        });
    },

    //处理视频文件的方法---.mp4文件
    beforeAvatarUploadVideo(file) {
      if (this.videoArr.length >= 9) {
        this.showErrorMsg("最多创建9个项目");
        return;
      }
      //限定只能是MP4文件并且视频大小不超过10MB
      // console.log("文件的类型是:", file.type);
      //mp4的限定
      const isMP4 = file.type === "video/mp4";

      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isMP4) {
        this.showErrorMsg("上传视频只能是 MP4 格式 !");
        return;
      }
      if (!isLt10M) {
        this.showErrorMsg("上传视频大小不能超过 10MB!");
        return;
      }

      //MP4并且大小限定5M
      const res = isMP4 && isLt10M;

      if (res) {
        //开启loading
        this.showUploadLoading();
        //上传文件
        this.uploadFile(2, file);
      }
      return res;
    },

    //显示Loading的方法,上传图片和视频时都需要显示出来
    showUploadLoading() {
      //进行文件的上传并显示Loading效果
      this.loading = this.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });
    },

    //关闭Loading的方法
    closeLoading() {
      //上传成功后关闭loading
      if (!this.isEmpty(this.loading)) {
        this.loading.close();
      }
    },

    //点击编辑的操作 type 1:编辑图片 2:编辑视频 3:编辑项目
    doEditAction(item, type) {
      //1:记录下当前操作的条目
      this.actionObj = item;

      //2:记录下当前操作的类型
      this.actionType = type;

      //3:显示编辑的弹框
      this.showEditDialog = true;
    },

    //点击删除的操作 type 1:编辑图片 2:编辑视频 3:编辑项目
    doDelAction(item, type) {
      //1:记录下当前操作的条目
      this.actionObj = item;

      //2:记录下当前操作的类型
      this.actionType = type;

      //3:显示编辑的弹框
      this.showDelDialog = true;
    },

    //编辑后的确定事件
    async editItemName(name) {
      //1:做编辑的上传服务器处理
      //2:请求成功后同步当前条目的状态
      //3:关闭编辑弹框
      let param = new Object();
      param.type = 2;
      param.id = this.actionObj.row_id;
      param.work_content = name;
      param.uid = this.getUserInfo().uid;
      param.token = this.getUserInfo().token;
      let res = await this.$http.fetchPost(this.$api.POST_ACTION, param);
      if (res.data.state == 200) {
        //更改成功后同步当前操作条目的名称即可
        this.actionObj.work_content = name;
        this.$refs.edit_name.showCommitLoading = false;
        //关闭弹框
        this.showEditDialog = false;
      } else {
        //直接将服务器返回的错误内容弹出提示
        this.showErrorMsg(this.getMesage(res.data.state));
        this.$refs.edit_name.showCommitLoading = false;
      }
    },

    //删除
    async delItem() {
      //1:做删除的上传服务器处理
      //2:请求成功后同步当前条目的状态
      //3:关闭删除弹框
      let param = new Object();
      param.type = 3;
      param.id = this.actionObj.row_id;
      param.work_type = this.actionType;
      param.uid = this.getUserInfo().uid;
      param.token = this.getUserInfo().token;
      param.work_file = this.getWorkFile(this.actionObj.work_file);
      let res = await this.$http.fetchPost(this.$api.POST_ACTION, param);
      if (res.data.state == 200) {
        //将当前记录的条目从对应的数组中移除
        if (this.actionType == 1) {
          this.remove(this.actionObj, this.imgArr);
        } else if (this.actionType == 2) {
          this.remove(this.actionObj, this.videoArr);
        } else if (this.actionType == 3) {
          this.remove(this.actionObj, this.projectArr);
        }
        //关闭删除Loading
        this.$refs.del_item.showCommitLoading = false;
        //关闭弹框
        this.showDelDialog = false;
      } else {
        this.$refs.del_item.showCommitLoading = false;
        //直接将服务器返回的错误内容弹出提示
        this.showErrorMsg(this.getMesage(res.data.state));
      }
    },

    //获取删除的文件名
    getWorkFile(work_file) {
      if (this.isEmpty(work_file)) {
        return;
      }
      return work_file.substring(work_file.lastIndexOf("/") + 1);
    },

    //创建项目点击确定后的操作
    doCreateCourse(name) {
      //这里是不是先需要请求后台接口判断项目名称是否重复
      //创建项目
      this.showCreateCourseDialog = false;

      //这里携带账号信息以及作品名称信息单独打开Scratch界面
      window.open(this.$api.SCRATCH3_URL, "_blank");
    },

    //显示播放视频的弹框
    showVideoDialog(item) {
      //记录一下播放对象
      this.playObj = item;
      this.viedoName = item.work_content;
      this.showPlayVideoDialog = true;
    },

    //显示创建项目的弹框
    showCrateProject(item) {
      if (this.projectArr.length >= 9 && !item.row_id) {
        this.showErrorMsg("最多创建9个项目");
        return;
      }
      //打开创建作品
      this.showCreateProjectDialog = true;
      if (item.row_id) {
        item.projectId = item.row_id;
        this.projectInfo = item;
      } else {
        this.projectInfo = "";
      }

      setTimeout(() => {
        var ts = new Date().getTime();
        let params = this.getRequest();
        this.iframeSrc = `${this.iframeHost}${
          item.row_id
            ? `#${item.row_id}&ts=${ts}&isStartCloud=true&uid=${
                this.getUserInfo().uid
              }&name=${item.work_file}&params=${JSON.stringify(params)}`
            : `?ts=${ts}&isStartCloud=true&uid=${this.getUserInfo().uid}&name=`
        }
                            &name=&uid=${
                              this.getUserInfo().uid
                            }&params=${JSON.stringify(params)}`;
      }, 800);

      setTimeout(() => {
        let scratch3Id = document.getElementById("scratch3Id");
        scratch3Id.contentWindow.postMessage({ isStartCloud: true }, "*");
      }, 2500);
    },

    /*
     * 获取url中"?"符后的参数
     *
     */
    getRequest() {
      var url = window.location.href;
      var theRequest;
      if (url.indexOf("?") != -1) {
        var urlArr = url.split("?");
        var paremtsArr = urlArr[1].split("&");
        theRequest = {};
        for (var i = 0; i < paremtsArr.length; i++) {
          theRequest[paremtsArr[i].split("=")[0]] = unescape(
            paremtsArr[i].split("=")[1]
          );
        }
      }
      return theRequest;
    },

    //打开项目的操作
    playItemProject(item) {
      item.projectId = item.row_id;
      this.projectInfo = item;
      this.showPlayProjectDialog = true;
    },
  },
  props: ["curriculumCenterId", "curriculumChapterId", "chapterClassId"],
  computed: {},
  components: {
    EditNameDialog,
    DelItemDialog,
    PlayVideoDialog,
    CreateCourse,
    PlayProjectDialog,
    ShowCreateProject,
  },
};
</script>
<style lang="scss" scoped>
.study_exercises {
  height: calc(100vh - 97px);
  overflow-y: auto;
  position: relative;
  .content {
    width: $common_width;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    font-size: 14px;

    .stem_content {
      margin: 16px 0;
      font-weight: bold;
    }

    //添加图片
    .add_img,
    .add_video,
    .add_project {
      display: flex;
      flex-wrap: wrap;
      .upload_img {
        margin-bottom: 20px;
        position: relative;
        background: #66bbfe;
        display: flex;
        justify-content: center;
        height: 200px;
        flex-direction: column;
        align-items: center;
        width: 23%;
        border-radius: 14px;
        font-weight: 1;
        cursor: pointer;
        margin-right: 30px;
        .add_text {
          font-size: 28px;
          color: white;
        }
        .add_icon {
          color: white;
          font-size: 60px;
          margin-top: 12px;
        }
      }

      //添加图片的列表条目
      .item_course {
        position: relative;
        margin-bottom: 20px;
        height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 23%;
        border-radius: 14px;
        color: black;
        font-weight: bold;
        cursor: pointer;
        box-shadow: darkgrey 0px 0px 7px 1px;

        .item_img {
          margin-top: 12px;
          width: 240px;
          height: 140px;
          object-fit: cover;
          border-radius: 4px;
        }

        .item_bottom {
          margin: 0 14px;
          font-weight: normal;
          flex: 1;
          width: 100%;
          display: flex;
          align-items: center;
          margin-top: 10px;

          .item_bottom_left {
            margin-left: 18px;
            flex: 1;
            overflow: hidden; /*超出部分隐藏*/
            white-space: nowrap; /*不换行*/
            text-overflow: ellipsis; /*超出部分文字以...显示*/
            font-size: 12px;
          }
          .item_bottom_right {
            margin-right: 18px;
            display: inline-block;
          }
        }
        .item_icon {
          font-size: 20px;
          margin-left: 4px;
        }
        .item_name {
          margin-top: 10px;
        }
      }

      .hasMargin {
        margin-right: 30px;
      }
      .noMargin {
        margin-right: 0px;
      }
    }

    .add_video {
      margin: 20px 0;
    }
    .add_project {
      margin-bottom: 20px;
    }
  }

  ::v-deep .el-upload {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
//去除滚动条
.study_exercises::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
</style>